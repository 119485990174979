export const CURRENCY_SYMBOLS = {
    INR: '₹',
};

export const PROVIDERS = {
    RAZORPAY: 2,
    PHONEPE: 3,
};

export const ERROR_MESSAGES = {
    UNAUTHORIZED: 'Unauthorized: Your login credentials are incorrect. Please try again.',
    PROCESSING_ERROR: 'Something went wrong while processing your request. Please try again later.',
    SERVER_ERROR: 'Server Error: We are having trouble on our end. Please try again later.',
    DEFAULT_ERROR: 'Oops! Something went wrong. Please try again later.',
};

export const PAYMENT_METHOD = 'UPI';

export const INR_CURRENCY_ID = 1;

export const RAZORPAY_PROVIDER_ID = 2;

export const ONLINE_PAYMENT_METHOD = 'ONLINE';