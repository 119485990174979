import React from 'react'

import styles from './styles.module.scss'

const ErrorFallback = ({ error }) => (
    <div className={styles.container}>
        <div className={styles.innerContainer}>
            <span className={styles.header}>{"Something Went Wrong"}</span>
            <span className={styles.message}>{error.message}</span>
            <button className={styles.button}>Try again</button>
        </div>
    </div>
)

export default ErrorFallback
