import { RAZORPAY_KEY_ID, name, RAZORPAY_CALLBACK_URL, optionsThemeColor } from '../../constants/config';

async function paymentHandler(amount, currency, orderId) {
    const paymentOptions = {
        key: RAZORPAY_KEY_ID,
        amount: amount * 100,
        currency,
        name,
        order_id: orderId,
        callback_url: RAZORPAY_CALLBACK_URL,
        theme: optionsThemeColor,
        redirect: true,
    };    

    const pay = new window.Razorpay(paymentOptions);
    pay.open();
}

export { paymentHandler };