import React, { useState } from 'react';

import PrimaryButton from '../../../widgets/Button/PrimaryButton.js';
import Dialog from '../../../widgets/Dialog/Dialog.js';
import WarningPopupLogo from '../../../assets/icons/warningpopupLogo.js';
import { CURRENCY_SYMBOLS } from '../../../constants/constants';
import Loader from '../../../components/Loader/Loader.js';

import styles from './OrderPaymentDetails.module.scss';

const openDialog = (setIsDialogOpen) => {
    setIsDialogOpen(true);
};

const closeDialog = (setIsDialogOpen) => {
    setIsDialogOpen(false);
};

const OrderPaymentDetails = (props) => {
    const { order, handleOnClickBack, handleClickContinue,isCreatingPaymentOrder } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const currencySymbol = CURRENCY_SYMBOLS[order.currency];

    return (
        <div className={styles.paymentContainer}>
            <h2 className={styles.heading}>Payment Amount</h2>
            <p className={styles.contentText}>{currencySymbol} {order.payment_amount}</p>
            <div className={styles.paymentBtnContainer}>
                <PrimaryButton onClick={() => openDialog(setIsDialogOpen)}>Proceed To Pay</PrimaryButton>
            </div>
            <PrimaryButton onClick={handleOnClickBack}>Go Back</PrimaryButton>
            <Dialog isOpen={isDialogOpen} handleOnClose={() => closeDialog(setIsDialogOpen)}>
                <div className={styles.dialogHeaderContainer}>
                    <div className={styles.dialogBackgroundCurve} />
                    <div className={styles.dialogBackgroundImageContainer}>
                        <WarningPopupLogo />
                    </div>
                </div>
                <div className={styles.dialogContentContainer}>
                    <p className={styles.dialogSubTitle}>
                        Do not press the back button while payment is in progress.
                    </p>
                    <div className={styles.dialogButtonContainer}>
                        <PrimaryButton onClick={handleClickContinue} disabled={isCreatingPaymentOrder}>{isCreatingPaymentOrder ? "Confirming...":"Confirm"}</PrimaryButton>
                        <div className={styles.dialogLink} onClick={() => closeDialog(setIsDialogOpen)}>Cancel</div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default OrderPaymentDetails;