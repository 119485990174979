import { ERROR_MESSAGES } from '../constants/constants';
import PageNotFound from '../components/PageNotFound/PageNotFound';

export const formatErrorDetailsByResponseStatusCode = (error) => {
    switch (error.statusCode) {
        case 401:
            return ERROR_MESSAGES.UNAUTHORIZED;
        case 404:
            return <PageNotFound />;
        case 422:
            return ERROR_MESSAGES.PROCESSING_ERROR;
        case 500:
            return ERROR_MESSAGES.SERVER_ERROR;
        default:
            return ERROR_MESSAGES.DEFAULT_ERROR;
    }
};