import { useMutation } from "@tanstack/react-query";

import fetchJSON from "../../helpers/restApiClient";
import {
  PAYMENT_ORDER_API_URL,
  PAYMENT_SECRET_KEY,
} from "../../constants/config";

import {
  INR_CURRENCY_ID,
  RAZORPAY_PROVIDER_ID,
  ONLINE_PAYMENT_METHOD
} from "../../constants/constants";

const punchPayment = async ({ amount, order_id }) => {
  return fetchJSON(PAYMENT_ORDER_API_URL, {
    method: "POST",
    headers: {
      "secret-key": PAYMENT_SECRET_KEY,
    },
    body: { order_id, amount, "currency_id": INR_CURRENCY_ID, "payment_details": { "provider_id": RAZORPAY_PROVIDER_ID, "payment_mode": ONLINE_PAYMENT_METHOD } }
  }).then((response) => ({
    gatewayOrderId: response.data.payment_details.provider_details.gateway_order_id,
  }));
};

export const usePunchPayment = (options) =>
  useMutation({
    mutationFn: punchPayment,
    ...options,
  });
