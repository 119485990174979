import React from 'react';

const WarningPopupLogo = () => {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg">
      <path d="M66.0972 116.098C93.7114 116.098 116.097 93.7119 116.097 66.0977C116.097 38.4834 93.7114 16.0977 66.0972 16.0977C38.4829 16.0977 16.0972 38.4834 16.0972 66.0977C16.0972 93.7119 38.4829 116.098 66.0972 116.098Z" fill="#F8B024" stroke="#F8B024" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M59.999 110C87.6133 110 109.999 87.6143 109.999 60C109.999 32.3858 87.6133 10 59.999 10C32.3848 10 9.99902 32.3858 9.99902 60C9.99902 87.6143 32.3848 110 59.999 110Z" fill="white" />
      <path d="M60 63.375V42.375" stroke="#F8B024" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <circle cx="60" cy="73.875" r="3" fill="#F8B024" />
    </svg>
  );
};

export default WarningPopupLogo;