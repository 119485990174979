import React from 'react';
import MuiDialog from '@mui/material/Dialog';
import styled from 'styled-components';

const StyledDialog = styled(MuiDialog)`
  && .MuiDialog-paperWidthSm {
    width: 100%;
    margin: 0;
    position: fixed;
    bottom: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media (min-width: 600px) {
    && .MuiDialog-paperWidthSm {
      position: relative;
      max-width: 440px;
      border-radius: 12px;
    }
  }
`;

const Dialog = (props) => {
  const { isOpen, handleOnClose, children } = props;
  return (
    <StyledDialog aria-labelledby="dialog-title" open={isOpen} onClose={handleOnClose}>
      {children}
    </StyledDialog>
  );
};

export default Dialog;