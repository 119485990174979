import { QueryCache, MutationCache, QueryClient } from "@tanstack/react-query";

import { logger } from "../utils/logger";
import { HOUR } from "../constants/queryCacheTimes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 12 * HOUR,
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: logger,
  }),
  mutationCache: new MutationCache({
    onError: logger,
  }),
});

export { queryClient };
