import React from 'react';

import { styled } from '@mui/system';

const StyledErrorText = styled('span')(({ theme }) => ({
    padding: '15px 0px',
    display: 'block',
    textAlign: 'center',
    fontSize: '1.2rem',
    color: '#d32f2f',
    fontFamily: 'Arial, sans-serif',
}));

const ErrorMessage = ({ children }) => {
    return (
        <div style={{ textAlign: 'center' }}>
            <StyledErrorText>{children || 'Something went wrong. Please try again.'}</StyledErrorText>
        </div>
    );
};

export default ErrorMessage;