import React from 'react'
import { Routes, Route } from 'react-router-dom'

import OrderPaymentsContainer from './containers/OrderPaymentContainer/OrderPaymentContainer'
import PageNotFound from './components/PageNotFound/PageNotFound'
import routes from './constants/routes'

const AppRoutes = () => (
    <Routes>
        <Route path={`${routes.orderPayments}/:orderId`} element={<OrderPaymentsContainer />} />
        <Route path="*" element={<PageNotFound />} />
    </Routes>
)

export default AppRoutes
