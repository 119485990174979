import { useQuery } from "@tanstack/react-query";

import fetchJSON from "../../helpers/restApiClient";
import { ORDER_API_URL } from "../../constants/config";

const fetchOrderDetails = async ({ queryKey }) => {
  const [_, orderId] = queryKey;
  const url = `${ORDER_API_URL}/${orderId}`;
  
  return fetchJSON(url, {
    method: "GET",
  }).then((response) => ({
    recipient_name: response.data.recipient_name,
    recipient_masked_phone_number: response.data.masked_phone_number,
    order_amount: response.data.order_amount,
    payment_amount: response.data.payment_link_amount,
    currency: response.data.currency,
    remaining_amount:response.data.remaining_amount
  }));
};

export const useOrder = (orderId) =>
  useQuery({ queryKey: ["order", orderId], queryFn: fetchOrderDetails });
