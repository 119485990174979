import React from 'react';

const NotFoundIcon = (props) => (
    <svg width="243" height="148" viewBox="0 0 243 148" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="243" height="148">
            <rect width="242.182" height="148" fill="#C4C4C4" />
        </mask>
        <g mask="url(#mask0)">
            <g clipPath="url(#clip0)">
                <path d="M198.766 78.5787L191.051 104.308L202.948 107.956L210.663 82.2268L198.766 78.5787Z" fill="#DCE3FF" />
                <path opacity="0.5" d="M196.199 87.1574L193.627 95.7344L205.524 99.3825L208.096 90.8055L196.199 87.1574Z" fill="#DCE3FF" />
                <path d="M193.632 95.752L191.059 104.328L202.954 107.978L205.527 99.4014L193.632 95.752Z" fill="#DCE3FF" />
                <path d="M221.145 110.832L227.371 90.0756C227.783 88.7037 227.015 87.2528 225.658 86.8357L210.669 82.2393L202.952 107.967L217.941 112.564C219.298 112.981 220.735 112.206 221.145 110.832Z" fill="#5163A1" />
                <path d="M211.771 84.7756L210.43 89.25L223.951 93.3963L225.293 88.9218L211.771 84.7756Z" fill="#778ACE" />
                <path d="M140.399 64.538L131.132 65.7563L137.075 71.0815L131.045 72.1241L138.556 78.1693L126.23 78.5161L132.743 83.9093L127.372 84.7895L191.057 104.321L198.774 78.5907L135.391 59.1514L140.399 64.538Z" fill="#ADBEF8" />
                <path d="M137.075 71.0813L131.045 72.124L138.556 78.1691L136.513 78.2284L193.629 95.7449L196.201 87.1666L133.602 67.9688L137.075 71.0813Z" fill="#DCE3FF" />
                <path d="M126.23 78.5161L132.743 83.9093L127.372 84.7895L191.057 104.321L193.629 95.745L136.513 78.2285L126.23 78.5161Z" fill="#ADBEF8" />
                <path d="M48.6508 109.422L45.9005 110.414L24.1586 108.022L15.543 107.076L21.6275 100.833L36.9745 85.0791L39.727 84.0869L48.6508 109.422Z" fill="#F4F2FF" />
                <path opacity="0.5" d="M45.6075 101.553L42.2884 92.1318L15.543 107.074L45.6075 101.553Z" fill="#F4F2FF" />
                <path d="M48.4099 109.51L45.907 110.41L15.543 107.076L45.6075 101.558L48.4099 109.51Z" fill="#F4F2FF" />
                <path d="M24.1586 108.022L15.543 107.076L21.6275 100.833L24.1586 108.022Z" fill="#5163A1" />
                <path d="M116.391 82.4733L107.864 82.0475L117.561 74.3692L108.096 74.0268L112.357 69.5357L104.419 68.8223L111.2 62.2284L104.061 60.918L37.4804 84.9011C36.5427 85.2391 36.5166 85.8559 37.4153 86.2818L39.8769 87.4429C40.7799 87.8666 41.1576 88.9443 40.7257 89.8509L39.5448 92.3247C39.3321 92.7725 39.3169 93.2642 39.4666 93.6988C39.6186 94.1378 39.9377 94.5198 40.3935 94.7327L42.8573 95.8939C43.7603 96.3197 44.1402 97.3953 43.7061 98.3018L42.5252 100.776C42.3146 101.221 42.2973 101.709 42.4449 102.139C42.5968 102.584 42.9159 102.966 43.3739 103.184L45.8355 104.345C46.7386 104.771 47.1163 105.846 46.6843 106.753L45.523 109.187C45.091 110.089 45.4991 110.552 46.4368 110.214L112.624 86.3739L116.391 82.4733Z" fill="#ADBEF8" />
                <path d="M117.561 74.3668L108.096 74.0243L112.357 69.5333L107.714 69.1162L39.4668 93.7008C39.6187 94.1398 39.9378 94.5217 40.3937 94.7346L42.8575 95.8958C43.7605 96.3216 44.1404 97.3972 43.7062 98.3038L42.5253 100.778C42.3148 101.223 42.2974 101.71 42.445 102.141L115.902 75.6816L117.561 74.3668Z" fill="#DCE3FF" />
                <path d="M116.39 82.4729L107.864 82.047L115.898 75.6836L42.4404 102.143C42.5924 102.588 42.9115 102.97 43.3695 103.188L45.8311 104.349C46.7341 104.775 47.1118 105.85 46.6798 106.757L45.5185 109.191C45.0865 110.093 45.4946 110.556 46.4324 110.218L112.62 86.3772L116.39 82.4729Z" fill="#ADBEF8" />
                <path d="M97.5143 93.4843L104.764 85.7964L107.46 87.7655L117.582 78.5504L106.53 84.9112L102.601 86.7271L97.5143 93.4843Z" fill="#DCE3FF" />
                <path d="M97.6401 100.553L92.6895 96.7611L96.5057 91.9574L102.327 92.9658L97.6401 100.553Z" fill="#DCE3FF" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="243" height="148" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default NotFoundIcon;