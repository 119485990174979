import React from 'react';

import NotFoundIcon from '.././../assets/icons/notFoundIcon';
import styles from './PageNotFound.module.scss';

const PageNotFound = () => (
    <div className={styles.root}>
        <NotFoundIcon className={styles.imgStyle} />
        <h1 className={styles.heading}>404: Page Not Found</h1>
        <p className={styles.subHeading}>Sorry, we can’t find the page! It might be an old link or maybe the page moved.</p>
    </div>
);

export default PageNotFound;
