import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

const PrimaryButton = styled(Button)(({ theme }) => `
    && {
        width: auto;
        padding: 10px 40px; 
        margin-bottom: 16px;
        font-size: 16px;
        border-radius: 60px; 
        background-color: #227aeb; 
        color: white; 
        border: none; 
        box-shadow: none; 
        text-transform: capitalize;
    }

    &&:hover {
        background-color: #1967CA; 
    }

    @media (min-width: 780px) {
        && {
            padding: 15px 30px;
            font-size: 16px;
            min-width: 240px;
            max-height: 52px;
        }
    }

    &:disabled {
        background-color: #e0e0e0;
        color: #a0a0a0;
      }
`);


export default PrimaryButton;