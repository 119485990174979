import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import throttle from 'lodash/throttle';

import OrdersDetails from './OrderDetails/OrderDetails';
import OrderPaymentDetails from './PaymentSelection/OrderPaymentDetails';
import Stepper from '../../widgets/Stepper/Stepper';
import Logo from '../../assets/icons/ILLogo';
import { useOrder } from '../../store/backend/order';
import { usePunchPayment } from '../../store/backend/usePunchPayment';
import { formatErrorDetailsByResponseStatusCode } from '../../helpers/errorFormatter';
import Loader from '../../components/Loader/Loader';
import { paymentHandler } from '../PaymentGateway/razorpayPaymentGateway';
import { ERROR_MESSAGES, PROVIDERS } from '../../constants/constants';
import ErrorMessage from '../../widgets/ErrorMessage/ErrorMessage';

import styles from './OrderPaymentContainer.module.scss';

const ORDER_DETAILS_STEP_INDEX = 1;
const ORDER_PAYMENT_DETAILS_STEP_INDEX = 2;

export const PAYMENT_STEPS = [
    {
        key: 1,
        label: 'Order Details Page',
    },
    {
        key: 2,
        label: 'Payment Selection Page',
    },
];

const handleOnConfirm = throttle((createPaymentOrder, orderId, orderAmount) => {
    createPaymentOrder({
        order_id: orderId,
        amount: orderAmount,
    });
}, 5000);

const OrderPaymentsContainer = () => {
    const INITIAL_STEP_INDEX = 1;
    const { orderId } = useParams()

    const [currentStep, setCurrentStep] = useState(INITIAL_STEP_INDEX);

    const { isLoading: isLoadingOrder, data: order, error: orderError } = useOrder(orderId);

    const { isPending: isCreatingPaymentOrder, mutate: createPaymentOrder, error: paymentError } = usePunchPayment({
        onSuccess: (punchPaymentResponse) => {
            paymentHandler(order.order_amount, order.currency, punchPaymentResponse.gatewayOrderId);
        },
    });

    if (isLoadingOrder) {
        return <Loader />;
    }
    
    if (orderError) {
        return (
            <div className={styles.errorContainer}>
                <ErrorMessage>{formatErrorDetailsByResponseStatusCode(orderError)}</ErrorMessage>
            </div>
        );
    }

    if (paymentError) {
        return (
            <div className={styles.errorContainer}>
                <ErrorMessage>{paymentError.message || ERROR_MESSAGES.PROCESSING_ERROR}</ErrorMessage>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <Logo />
            <Stepper steps={PAYMENT_STEPS} activeStep={currentStep - 1} />
            <div className={styles.stepsContainer}>
                {(() => {
                    switch (currentStep) {
                        case ORDER_DETAILS_STEP_INDEX:
                            return (
                                <OrdersDetails
                                    order={order}
                                    handleOnClickNext={() => setCurrentStep(prevStep => prevStep + 1)}
                                />
                            );
                        case ORDER_PAYMENT_DETAILS_STEP_INDEX:
                            return (
                                <OrderPaymentDetails
                                    order={order}
                                    handleOnClickBack={() => setCurrentStep(prevStep => prevStep - 1)}
                                    handleClickContinue={() => handleOnConfirm(createPaymentOrder,orderId,order.payment_amount)}
                                    isCreatingPaymentOrder={isCreatingPaymentOrder}
                                />
                            );
                        default:
                            return null;
                    }
                })()}
            </div>
        </div>
    );
};

export default OrderPaymentsContainer;
