import React from 'react';
import PrimaryButton from '../../../widgets/Button/PrimaryButton';
import { CURRENCY_SYMBOLS } from '../../../constants/constants';

import styles from './OrderDetails.module.scss';

const OrdersDetails = (props) => {
    const { order, handleOnClickNext } = props;
    const currencySymbol = CURRENCY_SYMBOLS[order.currency];

    return (
        <div className={styles.paymentContainer}>
            <h2 className={styles.heading}>Recipient Name</h2>
            <p className={styles.contentText}>{order.recipient_name}</p>
            <h2 className={styles.heading}>Mobile Number</h2>
            <p className={styles.contentText}>{order.recipient_masked_phone_number}</p>
            <h2 className={styles.heading}>Order Amount</h2>
            <p className={styles.contentText}>{currencySymbol} {order.order_amount}</p>
            <h2 className={styles.heading}>Remaining Amount</h2>
            <p className={styles.contentText}>{currencySymbol} {order.remaining_amount}</p>
            <div className={styles.paymentBtnContainer}>
                <PrimaryButton onClick={handleOnClickNext}>Next</PrimaryButton>
            </div>
        </div>
    );
};

export default OrdersDetails;