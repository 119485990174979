import React from 'react'
import MUIStepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/system'

const StyledStepper = styled('div')(({ theme }) => ({
  width: '100%',
  margin: '8px 0px'
}))

const Stepper = (props) => {
  const { steps, activeStep } = props
  return (
    <StyledStepper>
      <MUIStepper activeStep={activeStep} alternativeLabel>
        {steps.map((step) => (
          <Step key={step.key}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </MUIStepper>
    </StyledStepper>
  )
}

export default Stepper