import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';

import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import Routes from './Routes';
import { NODE_ENV } from './constants/config';
import { logger } from './utils/logger';
import { queryClient } from './store/serverStore';

import './App.scss';

function App() {

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logger}>
      <QueryClientProvider client={queryClient}>
        {NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
        <Router>
          <Routes />
        </Router>
      </QueryClientProvider>
    </ErrorBoundary>
  );

}

export default App;